import { Formik, FormikErrors } from 'formik'
import moment from 'moment'
import React from 'react'
import Hero from '../components/hero'
import { useUiKit } from '../hooks/uikit'
import { PageProps } from 'gatsby'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  departement: string
  title: string
  company: string
  phone: string
  schaduleDate: string
  schaduleHour: string
}

interface FormClientPageProps extends PageProps {}

const FormClientPage = ({ location }: FormClientPageProps): JSX.Element => {
  const uikit = useUiKit()
  const params = new URLSearchParams(location.search)
  const email = params.get('email')

  return (
    <>
      <Hero>
        <h1 className="uk-heading-small">Become Our Customer</h1>
        <p className="uk-width-xlarge">You are in the right place, let's get in touch with us.</p>
      </Hero>
      <section className="uk-section">
        <div className="uk-container">
          <div className="uk-width-xlarge uk-margin-auto uk-margin-bottom">
            <div className="uk-alert-primary" uk-alert="">
              <a className="uk-alert-close" uk-close=""></a>
              <p>Please complate the form bellow to claim your reward.</p>
            </div>
          </div>

          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email,
              departement: '',
              title: '',
              company: '',
              phone: '',
              schaduleDate: moment().add('1', 'day').format('YYYY-MM-DD'),
              schaduleHour: moment().format('hh:mm'),
            }}
            validate={(values) => {
              const errors: FormikErrors<FormValues> = {}
              if (!values.firstName) {
                errors.firstName = 'Required'
              } else if (!/([a-zA-Z]{3,30}\s*)+/i.test(values.firstName)) {
                errors.firstName = 'Invalid format'
              }
              if (!values.lastName) {
                errors.lastName = 'Required'
              } else if (!/[a-zA-Z]{3,30}/i.test(values.lastName)) {
                errors.lastName = 'Invalid format'
              }
              if (!values.email) {
                errors.email = 'Required'
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address'
              }
              if (!values.departement) {
                errors.departement = 'Required'
              } else if (!/[a-zA-Z]+/i.test(values.departement)) {
                errors.departement = 'Invalid format'
              }
              if (!values.title) {
                errors.title = 'Required'
              } else if (!/[a-zA-Z]+/i.test(values.title)) {
                errors.title = 'Invalid format'
              }
              if (!values.company) {
                errors.company = 'Required'
              }
              if (!values.phone) {
                errors.phone = 'Required'
              } else if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i.test(values.phone)) {
                errors.phone = 'Invalid phone number'
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                department: values.departement,
                title: values.title,
                company_name: values.company,
                phone_number: values.phone,
                schedule: values.schaduleDate + ' ' + values.schaduleHour + ':00',
              }

              fetch(`https://jasnitacms.jasnita.co.id/api/demo-schedule`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json',
                  // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data), // body data type must match "Content-Type" header
              })
                .then((res) => {
                  uikit.notification({ message: 'Your data has sended', status: 'success' })
                })
                .catch((reason) => {
                  uikit.notification({ message: 'Sorry, please try again', status: 'warning' })
                })
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form className="uk-width-xlarge uk-margin-auto" onSubmit={handleSubmit}>
                <fieldset className="uk-fieldset">
                  <legend className="uk-legend">Customer Detail</legend>
                  <div className="uk-margin uk-grid" uk-grid="">
                    <div className="uk-width-1-2@m">
                      <input
                        className={errors.firstName && touched.firstName ? 'uk-input uk-form-danger' : 'uk-input'}
                        type="text"
                        placeholder="First Name"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                      {errors.firstName && touched.firstName && (
                        <small className="uk-text-danger">{errors.firstName}</small>
                      )}
                    </div>
                    <div className="uk-width-1-2@m">
                      <input
                        className={errors.lastName && touched.lastName ? 'uk-input uk-form-danger' : 'uk-input'}
                        type="text"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      {errors.lastName && touched.lastName && (
                        <small className="uk-text-danger">{errors.lastName}</small>
                      )}
                    </div>
                  </div>

                  <div className="uk-margin">
                    <input
                      type="email"
                      className={errors.email && touched.email ? 'uk-input uk-form-danger' : 'uk-input'}
                      placeholder="Email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email ? values.email : ''}
                      disabled
                    />
                    {errors.email && touched.email && <small className="uk-text-danger">{errors.email}</small>}
                  </div>

                  <div className="uk-margin">
                    <input
                      type="text"
                      className={errors.departement && touched.departement ? 'uk-input uk-form-danger' : 'uk-input'}
                      placeholder="Departement"
                      name="departement"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.departement}
                    />
                    {errors.departement && touched.departement && (
                      <small className="uk-text-danger">{errors.departement}</small>
                    )}
                  </div>

                  <div className="uk-margin">
                    <input
                      type="text"
                      className={errors.title && touched.title ? 'uk-input uk-form-danger' : 'uk-input'}
                      placeholder="Title"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {errors.title && touched.title && <small className="uk-text-danger">{errors.title}</small>}
                  </div>

                  <div className="uk-margin">
                    <input
                      type="text"
                      className={errors.company && touched.company ? 'uk-input uk-form-danger' : 'uk-input'}
                      placeholder="Company Name"
                      name="company"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                    />
                    {errors.company && touched.company && <small className="uk-text-danger">{errors.company}</small>}
                  </div>

                  <div className="uk-margin">
                    <input
                      type="tel"
                      className={errors.phone && touched.phone ? 'uk-input uk-form-danger' : 'uk-input'}
                      placeholder="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone && <small className="uk-text-danger">{errors.phone}</small>}
                  </div>
                </fieldset>
                <fieldset className="uk-fieldset">
                  <legend className="uk-legend">Schadule Meeting</legend>

                  <div className="uk-margin">
                    <input
                      type="date"
                      className="uk-input"
                      min={moment().format('YYYY-MM-DD')}
                      name="schaduleDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.schaduleDate}
                    />
                  </div>

                  <div className="uk-margin">
                    <input
                      type="time"
                      className="uk-input"
                      name="schaduleHour"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.schaduleHour}
                    />
                  </div>
                </fieldset>
                <div className="uk-margin-large-top uk-form-width-small">
                  <button type="submit" className="uk-input uk-button-primary">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </section>
    </>
  )
}

export default FormClientPage
